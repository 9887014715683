html {
  background-color: aliceblue;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #4184A5;
}

.app__header {
  background-color: aliceblue;
}

.App-link {
  color: white;
}

main {
  padding: 5px;
  background-color:  rgb(86, 128, 200);
  border-radius: 30px;
  color: white;
  margin: 10px
}

@media only screen and (min-width: 800px) {
  main {
    width: 800px;
    margin: auto;
  }
}

.header__link {
  text-decoration: none;
  color: rgb(86, 128, 200);
}

.header__link:clicked {
  color: rgb(86, 128, 200);
}

.app__contact {
  display: flex;
  flex-direction: column;
}

.app__welcome__list {
  list-style: none;
  padding-left: 0;
}

.app__hours {
  margin-top: 30px;
}

.app__hours__list {
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
  font-size: 0.85em;
  width: 90%;
  padding-bottom: 10px;
}

@media only screen and (min-width: 800px) {
  .app__hours__list {
    width: 500px;
    font-size: 1.2em;
  }
}

.app__hours__list__day {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

nav {
  margin-bottom: 15px;
}

.app__header__letterImage {
  height: 1em;
}

.app__header__logo {
  height: 1.5em;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: -0.3em;
}

.contact__form {
  display: table;
  margin: auto;
}

.contact__form label {
  display: table-cell;
  vertical-align: middle;
}

.contact__form p { 
  display: table-row; 
}

.contact__form input {
  display: table-cell;
  width: 80%;
}

.contact__form textarea {
  resize: none;
  display: table-cell;
  width: 80%;
}

.contact__form button {
  display: table-cell
}

.app__about__pod, .app__about__staff {
  margin: auto;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 440px;
}

.app__about__pod img, .app__about__staff img {
  width: 100px;
  border-radius: 50%;
  margin-top: 15px;
}

.footer__link {
  text-decoration: none;
  color: rgb(86, 128, 200);
  font-weight: bold;
}

.footer__link:clicked {
  color: rgb(86, 128, 200);
}



.map {
  margin-top: 20px;
  width: 90%;
  height: 30vh;
  border-radius: 5px;
}

nav {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 700px;
}

nav a{
  color: white;
  font-weight: bold;
  margin: 10px;
  border-radius: 5px;
  background-color: rgb(86, 128, 200);
  padding: 4px;
  text-decoration: none;
}

.fees table {
  text-align: left;
  border-collapse: collapse;
  margin: 5px;
}

.fees table td {
  border: 1px solid rgb(86, 128, 200);
  background-color: white;
  color: rgb(86, 128, 200);
}

.mini__header {
  padding: 4px;
}

.footer__buzz {
  font-weight: normal;
}

.buzz__span {
  color: darkblue;
}

.app__contact__link {
  text-decoration: none;
  color:white;
}

.app__contact__link:clicked {
  color:white;
}

#fb {
  width: 50px;
}

.new__patient__list {
  text-align: left;
}

.new__patient__list li {
  margin-top: 3px;
}

.login__button {
  margin-left: 20px;
}

fieldset {
  border: 0;
}

.small__input {
  width: 100px
}

.big-input {
  width: 400px;
}

main, nav>a {
  box-shadow: 5px 5px 5px #4184A5;
}